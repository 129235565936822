/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        
		var offset = 200;
		var duration = 500;
	
		$(window).scroll(function() {
			if ($(this).scrollTop() > offset){  
			    $('body').addClass("sticky");
			    $('.sticky-header').addClass("visible");
			}
			else{
			    $('body').removeClass("sticky");
			    $('.sticky-header').removeClass("visible");
			}
		});  
        
		// Animated buger icon that activates page slide
		var Menu = {
		  el: {
			ham: $('.menu-button'),
			menuTop: $('.menu-top'),
			menuMiddle: $('.menu-middle'),
			menuBottom: $('.menu-bottom'),
			menuPanel: $('.cd-panel'),
			menuBodyBg: $('body')
		  },
		  init: function() {
			Menu.bindUIactions();
		  },
		  bindUIactions: function() {
			  Menu.el.ham
				  .on(
				  'click',
				  function(event) {
					  Menu.activateMenu(event);
					  event.preventDefault();
				  }
			  );
		  },
		  activateMenu: function() {
			  Menu.el.menuTop.toggleClass('menu-top-click');
			  Menu.el.menuMiddle.toggleClass('menu-middle-click');
			  Menu.el.menuPanel.toggleClass('is-visible'); 
			  Menu.el.menuBottom.toggleClass('menu-bottom-click'); 
			  Menu.el.menuBodyBg.toggleClass('panel-open'); 
		  }
		};
		Menu.init();
		
	
		// Main navigation slidedown submenu
		$('.cd-panel-content ul.sub-menu').closest('li').addClass('drop');
		
		// Left Sidebar Main Navigation
		var menu_ul = $('.cd-panel-content .panel-menu > li.drop > .sub-menu'),
			menu_a  = $('.cd-panel-content .panel-menu > li.drop > .arrow');
		
		menu_ul.hide();
		menu_a.click(function(e) {
			e.preventDefault();
			if(!$(this).hasClass('active')) {
				menu_a.removeClass('active');
				menu_ul.filter(':visible').slideUp('normal');
				//menu_li.addClass('active-submenu');
				$(this).addClass('active').next().stop(true,true).slideDown('normal');
			} else {
				$(this).removeClass('active');
				$(this).next().stop(true,true).slideUp('normal');
			}
		});
		$('.cd-panel-content .current-menu-parent').find('.cd-panel-content .sub-menu').slideToggle('normal');      
		
		
		var offset = 220;
		var duration = 500;
        
        $(window).scroll(function() {
			if ($(this).scrollTop() > offset) {
				$('.back-to-top').fadeIn(duration);
			} else {
				$('.back-to-top').fadeOut(duration);
			}
		});
		
		$('.back-to-top').click(function(event) {
			event.preventDefault();
			$('html, body').animate({
				scrollTop: 0
			}, duration);
			return false;
		});
		
		
		$('a[href^="#"]').on('click',function (e) {
		    e.preventDefault();
	
		    var target = this.hash;
		    var $target = $(target);
	
		    $('html, body').stop().animate({
		        'scrollTop': $target.offset().top
		    }, 900, 'swing', function () {
		        window.location.hash = target;
		    });
		});   
        
        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
